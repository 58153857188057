import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import FsLightbox from 'fslightbox-react';

import { Breadcrumb, Layout } from '@app/components';

import { useLocale } from '@app/hooks';

const MEDIAS = [
  'https://www.youtube.com/watch?v=YBpDq4lWXBQ',
  'https://www.youtube.com/watch?v=zdDQzEW3iKY',
  'https://www.youtube.com/watch?v=HA4c96bBF88',
  'https://www.youtube.com/watch?v=hHto8JL0Qiw',
  'https://www.youtube.com/watch?v=mg7pFG1tuas',
  'https://www.youtube.com/watch?v=14-9WgK1ev8',
  'https://www.youtube.com/watch?v=HgWWqeMSxrE',
  'https://www.youtube.com/watch?v=AHdKTo6cLKY',
  'https://www.youtube.com/watch?v=R7Cjob-KlIA',
  'https://www.youtube.com/watch?v=cG0C5Wm51Jg',
  'https://www.youtube.com/watch?v=9pCGK6uoUuk',
  'https://www.youtube.com/watch?v=9y8vyCwPyEY',
  'https://www.youtube.com/watch?v=_tkDIKnLKC4',
  'https://www.youtube.com/watch?v=mLL_9qMwaGQ',
  'https://www.youtube.com/watch?v=JRstyVmKbfI',
  'https://www.youtube.com/watch?v=du_ExNfv8BQ',
  'https://www.youtube.com/watch?v=MWyyxWd2u-A',
  'https://www.youtube.com/watch?v=jbnfJ-uXRaE',
  'https://www.youtube.com/watch?v=WZBzsP0_uuo',
  'https://www.youtube.com/watch?v=h4EzltopfS4',
  'https://www.youtube.com/watch?v=4SBjbi5g34M',
  'https://www.youtube.com/watch?v=ELVV6WVvKyg',
  'https://www.youtube.com/watch?v=TDWkQkREzCA',
  'https://www.youtube.com/watch?v=4YhmhrwJvI8',
  'https://www.youtube.com/watch?v=Gno6DuBWJjw',
  'https://www.youtube.com/watch?v=5fDDUF3JPUo',
  'https://www.youtube.com/watch?v=fG-tiG2lKAA',
  'https://www.youtube.com/watch?v=2vTYfxbxQZg',
  'https://www.youtube.com/watch?v=YPVQHRhiQoU',
  'https://www.youtube.com/watch?v=F68uvaGghY4',
  'https://www.youtube.com/watch?v=CFyL-bBW5bc',
  'https://www.youtube.com/watch?v=sn7K2id6I3w',
  'https://www.youtube.com/watch?v=zsXXTE9jczI',
  'https://www.youtube.com/watch?v=FVIzPJ1UFc0',
  'https://www.youtube.com/watch?v=aaX1lh1JfTc',
  'https://www.youtube.com/watch?v=NjP0Y3ha59s',
  'https://www.youtube.com/watch?v=Ye98qfiGzoY',
  'https://www.youtube.com/watch?v=oCRDcgBXPwc',
  'https://www.youtube.com/watch?v=VuwQ0S2OEJY',
  'https://www.youtube.com/watch?v=GVcycOBVBkg',
  'https://www.youtube.com/watch?v=P8amgQYptjI',
  'https://www.youtube.com/watch?v=wMQl7iuWMFU',
  'https://www.youtube.com/watch?v=TS6T-r_Ol9I',
  'https://www.youtube.com/watch?v=QUM-upGH1e4',
  'https://www.youtube.com/watch?v=0QUdFbCv80U',
  'https://www.youtube.com/watch?v=5SzP5cPnKuQ',
  'https://www.youtube.com/watch?v=BVklQw9QhNU',
  'https://www.youtube.com/watch?v=BoNlQLOxLaQ',
  'https://www.youtube.com/watch?v=1sNZBQb5i80',
  'https://www.youtube.com/watch?v=bRUzusDKouc',
  'https://www.youtube.com/watch?v=wVysVyvuNk8',
  'https://www.youtube.com/watch?v=03xEwmgMuvg',
  'https://www.youtube.com/watch?v=tlNYnIGbt-Q',
  'https://www.youtube.com/watch?v=XShu13KkJ9A',
  'https://www.youtube.com/watch?v=Ts6wbg5OHgo',
  'https://www.youtube.com/watch?v=-tm4RHcu7b8',
  'https://www.youtube.com/watch?v=gi5Mp2LeYkI',
  'https://www.youtube.com/watch?v=UTXq5MTNrO4',
  'https://www.youtube.com/watch?v=FexWpTQR36c',
  'https://www.youtube.com/watch?v=xuPdKlxgu4I',
  'https://www.youtube.com/watch?v=qs8xcoNagFY',
  'https://www.youtube.com/watch?v=qFB18iVcZMI',
  'https://www.youtube.com/watch?v=AP0A2mEUaSg',
  'https://www.youtube.com/watch?v=_iTXUSbZqac',
  'https://www.youtube.com/watch?v=G0z1aOjtuOE',
  'https://www.youtube.com/watch?v=gYozAWYP1_A',
  'https://www.youtube.com/watch?v=hJsGJsDsJpI',
  'https://www.youtube.com/watch?v=IwBo3Zy0RlY',
  'https://www.youtube.com/watch?v=xZerE6TycY4',
  'https://www.youtube.com/watch?v=ghS8tSSss7Y',
  'https://www.youtube.com/watch?v=oMFwL2Mngns',
  'https://www.youtube.com/watch?v=INQh5zAz5qk',
  'https://www.youtube.com/watch?v=Y-gQd-xpnaE',
  'https://www.youtube.com/watch?v=5039jogvtrY',
  'https://www.youtube.com/watch?v=mSwL4UIXuZ0',
  'https://www.youtube.com/watch?v=kXkcQkV50d0',
  'https://www.youtube.com/watch?v=t0J3OPKWxL8',
  'https://www.youtube.com/watch?v=47oyTI6DqC0',
  'https://www.youtube.com/watch?v=554xmf6c3cM',
  'https://www.youtube.com/watch?v=0f1SnEoKW2I',
  'https://www.youtube.com/watch?v=LWkpUzjmzEg',
  'https://www.youtube.com/watch?v=IJsVTlYN3aY',
  'https://www.youtube.com/watch?v=uiYRPOOymM8',
  'https://www.youtube.com/watch?v=brzXndwjKR8',
  'https://www.youtube.com/watch?v=xLi3hMNc-BM',
  'https://www.youtube.com/watch?v=j5rKQIZzit8',
  'https://www.youtube.com/watch?v=GhhG69J41Zw',
  'https://www.youtube.com/watch?v=P6p7mcGCFS4',
  'https://www.youtube.com/watch?v=nV0XefAW6rA',
  'https://www.youtube.com/watch?v=WhrkocUAeLo',
  'https://www.youtube.com/watch?v=_uoHui8kYFQ',
  'https://www.youtube.com/watch?v=vaVHQS5Q5nw',
  'https://www.youtube.com/watch?v=fHdY2JxUCQQ',
  'https://www.youtube.com/watch?v=800CPH3k00M',
  'https://www.youtube.com/watch?v=o3XI8zZO7CY',
  'https://www.youtube.com/watch?v=n4_yP-2Wtv8',
  'https://www.youtube.com/watch?v=d2VzyDWiaRY',
  'https://www.youtube.com/watch?v=NvGkIUJa_SI',
  'https://www.youtube.com/watch?v=Nou8KyIQdsU',
  'https://www.youtube.com/watch?v=EpAvvWV3ZR0',
  'https://www.youtube.com/watch?v=I17CkRufIHc',
  'https://www.youtube.com/watch?v=Pacoh0K3dCY',
  'https://www.youtube.com/watch?v=vKEn2VSRwMs',
  'https://www.youtube.com/watch?v=v4vSrOEv_3Q',
  'https://www.youtube.com/watch?v=zvSza4O54D4',
  'https://www.youtube.com/watch?v=uGjh_1MZcYY',
  'https://www.youtube.com/watch?v=wpp4hWn7MZ4',
  'https://www.youtube.com/watch?v=I45PSOA3J58',
  'https://www.youtube.com/watch?v=V8a1n86cKr4',
  'https://www.youtube.com/watch?v=qz9KBYXyOOo',
  'https://www.youtube.com/watch?v=45RK67xGdME',
  'https://www.youtube.com/watch?v=P1nAUdCoNxc',
  'https://www.youtube.com/watch?v=8SLmh6BHGKw',
  'https://www.youtube.com/watch?v=PYT_C-KCZlM&',
  'https://www.youtube.com/watch?v=YPqBuBoNHS4',
  'https://www.youtube.com/watch?v=717p_QHxjLc&',
  'https://www.youtube.com/watch?v=i1MXqr0BO3s',
  'https://www.youtube.com/watch?v=iMOirz0-nmY',
  'https://www.youtube.com/watch?v=uQEI2x-qinQ',
  'https://www.youtube.com/watch?v=XZmJjTDjZLI',
  'https://www.youtube.com/watch?v=qUUwZc-DJF8',
  'https://www.youtube.com/watch?v=g7S_rUm9MiE',
  'https://www.youtube.com/watch?v=wYjLrUiTsHY',
  'https://www.youtube.com/watch?v=FqZFLHelueQ',
  'https://www.youtube.com/watch?v=h_I3NGGYrpw',
  'https://www.youtube.com/watch?v=frDJfltWBlo',
  'https://www.youtube.com/watch?v=Xrdl0lwXu58',
  'https://www.youtube.com/watch?v=WxK7pImI3bk',
  'https://www.youtube.com/watch?v=imQrvcxDJNk',
  'https://www.youtube.com/watch?v=S4_sEfOKhds',
  'https://www.youtube.com/watch?v=llxeCFzRy4M',
  'https://www.youtube.com/watch?v=IeojpAxiVqg',
  'https://www.youtube.com/watch?v=auTj_f3eRP4',
  'https://www.youtube.com/watch?v=8zmOmU8e8JU',
  'https://www.youtube.com/watch?v=_OebhYk4muo',
  'https://www.youtube.com/watch?v=WJngo2_I7u8',
  'https://www.youtube.com/watch?v=pIF0om8Gsrk',
  'https://www.youtube.com/watch?v=r7yCKFSmQnk',
  'https://www.youtube.com/watch?v=dIYkX8m37ug',
  'https://www.youtube.com/watch?v=o9AW_iwhIwk',
  'https://www.youtube.com/watch?v=cU_BCKyZacA',
  'https://www.youtube.com/watch?v=9ZiyYA_Owwo',
  'https://www.youtube.com/watch?v=NGGDwlpYC6s',
  'https://www.youtube.com/watch?v=4H-TRzK0OW8',
  'https://www.youtube.com/watch?v=AsB7EP6IUk0',
  'https://www.youtube.com/watch?v=XYpODLlHL54',
  'https://www.youtube.com/watch?v=L48mgn04ZZk',
  'https://www.youtube.com/watch?v=pTzjo3YKHGQ',
  'https://www.youtube.com/watch?v=kJqtF7GSkOY',
  'https://www.youtube.com/watch?v=G7Dqbq7pLt0',
  'https://www.youtube.com/watch?v=kkb9O2KqSuE',
  'https://www.youtube.com/watch?v=-xFy_5Kulww',
  'https://www.youtube.com/watch?v=A63-iVv4x_I',
  'https://www.youtube.com/watch?v=f5PJ7FEs6DM',
  'https://www.youtube.com/watch?v=43oxzu4tFuI',
  'https://www.youtube.com/watch?v=KNbwVxyucjQ',
  'https://www.youtube.com/watch?v=oKqSFEvXSwg',
  'https://www.youtube.com/watch?v=caj3G0WO9X0',
  'https://www.youtube.com/watch?v=uQGQBrwt7YE',
  'https://www.youtube.com/watch?v=Ld8MmiD_wuc',
  'https://www.youtube.com/watch?v=E8TlUm6Xh-s',
  'https://www.youtube.com/watch?v=EKWUy-FT8-I',
  'https://www.youtube.com/watch?v=W8QhTbWcfUE',
  'https://www.youtube.com/watch?v=nZogiiZMEtY',
  'https://www.youtube.com/watch?v=MSB3h15aGIo',
  'https://www.youtube.com/watch?v=VqCbuN77eDU',
  'https://www.youtube.com/watch?v=wUlFCsKo1HQ',
  'https://www.youtube.com/watch?v=aGGBc3kVYCo',
  'https://www.youtube.com/watch?v=eQm6fhiSEc4',
  'https://www.youtube.com/watch?v=k2C9gDlQH0Q',
  'https://www.youtube.com/watch?v=xsMCAROtr8I',
  'https://www.youtube.com/watch?v=5thd-MtkyEc',
  'https://www.youtube.com/watch?v=llFyu-x5MEk',
  'https://www.youtube.com/watch?v=5PXgaKV1tUs',
  'https://www.youtube.com/watch?v=UCPmXEeksVk',
  'https://www.youtube.com/watch?v=qTwlx338dR4',
  'https://www.youtube.com/watch?v=g_N_EcHjASM',
  'https://www.youtube.com/watch?v=8J3oo6OsUOE',
  'https://www.youtube.com/watch?v=75m5Y_2xQVQ',
  'https://www.youtube.com/watch?v=PDeP-uwY3QI',
  'https://www.youtube.com/watch?v=PUBfQn0e1RM',
  'https://www.youtube.com/watch?v=oaD6SH9X72M',
  'https://www.youtube.com/watch?v=uOoVIBxW8f0',
  'https://www.youtube.com/watch?v=nIZ5LcyN56c',
  'https://www.youtube.com/watch?v=IXHqsMPMle8',
  'https://www.youtube.com/watch?v=f6mr5RvtvgM',
  'https://www.youtube.com/watch?v=MaB3cz3QaQY',
  'https://www.youtube.com/watch?v=FY8zagBqshQ',
  'https://www.youtube.com/watch?v=MbNeCs3kiI4',
  'https://www.youtube.com/watch?v=v1R6QDB0oNc',
  'https://www.youtube.com/watch?v=YuqRC5UibDg',
  'https://www.youtube.com/watch?v=t6XqAeEZ8Eg',
  'https://www.youtube.com/watch?v=ReZS6LsY9g4',
  'https://www.youtube.com/watch?v=ZAC8qN6KJbw',
  'https://www.youtube.com/watch?v=7hWlnkXSIXE',
  'https://www.youtube.com/watch?v=RedznKjIx_o',
  'https://www.youtube.com/watch?v=R-mEcxf_a_Y',
  'https://www.youtube.com/watch?v=zj5qBf9UxDw',
  'https://www.youtube.com/watch?v=yp90RDoTSDQ',
  'https://www.youtube.com/watch?v=KN-vMKTG6HM',
  'https://www.youtube.com/watch?v=er8aX3flnEY',
  'https://www.youtube.com/watch?v=v3stQpC-eK8',
  'https://www.youtube.com/watch?v=7hY2F-AOBVY',
  'https://www.youtube.com/watch?v=Z97g1Wsaozo',
  'https://www.youtube.com/watch?v=AtTbt3yLiL8',
  'https://www.youtube.com/watch?v=VaLHPAJiJVs',
  'https://www.youtube.com/watch?v=84JgJYZB-Yg',
  'https://www.youtube.com/watch?v=KsJUOGYKnm8',
  'https://www.youtube.com/watch?v=RUGuLV1_LU8',
  'https://www.youtube.com/watch?v=Nei_UymdH1g',
  'https://www.youtube.com/watch?v=EPKWaZSmuLs',
  'https://www.youtube.com/watch?v=rg221_bBbH0',
  'https://www.youtube.com/watch?v=bpqXjkOZJYs',
  'https://www.youtube.com/watch?v=I4GLtlkHfNs',
  'https://www.youtube.com/watch?v=W4OpZp4x5sY',
  'https://www.youtube.com/watch?v=iTvKpfgftjM',
  'https://www.youtube.com/watch?v=g1GtO0tWJzU',
  'https://www.youtube.com/watch?v=JlBstdFmaa4',
  'https://www.youtube.com/watch?v=lqCDeU-Fuhs',
  'https://www.youtube.com/watch?v=_HjMVrGyIhY',
  'https://www.youtube.com/watch?v=CeDprNt6HcI',
  'https://www.youtube.com/watch?v=79iOA0iuSjw',
  'https://www.youtube.com/watch?v=b6MUi1xTzAM',
  'https://www.youtube.com/watch?v=WiNoVSQvDRM',
  'https://www.youtube.com/watch?v=bsSwz-nOaqs',
  'https://www.youtube.com/watch?v=wRgjPIGsY0E',
  'https://www.youtube.com/watch?v=J1-ojpATJGc',
  'https://www.youtube.com/watch?v=lJBFetV_IJg',
  'https://www.youtube.com/watch?v=eLtSr1CSzdY',
  'https://www.youtube.com/watch?v=KwahD8qZ0Ik',
  'https://www.youtube.com/watch?v=Q4tHGbPGg1U',
  'https://www.youtube.com/watch?v=Z1RSyb4niKI',
  'https://www.youtube.com/watch?v=dcm1KfM6CuA',
  'https://www.youtube.com/watch?v=hCvaegDduh8',
  'https://www.youtube.com/watch?v=1NXnUa3yl3g',
  'https://www.youtube.com/watch?v=fo2aabPqKMc',
  'https://www.youtube.com/watch?v=udA_ISljRnQ',
  'https://www.youtube.com/watch?v=oSIHDsja8hQ',
  'https://www.youtube.com/watch?v=COIWcSNL4Q0',
  'https://www.youtube.com/watch?v=NmA79aZEdzM',
  'https://www.youtube.com/watch?v=z8jskCN3lsE',
  'https://www.youtube.com/watch?v=xCty6xK6O6E',
  'https://www.youtube.com/watch?v=-1k9VeuTPO8',
  'https://www.youtube.com/watch?v=l69sICewpMs',
  'https://www.youtube.com/watch?v=uNq4y3bDfQk',
  'https://www.youtube.com/watch?v=mYw0JGboJM0',
  'https://www.youtube.com/watch?v=xNmZp3rpxds',
  'https://www.youtube.com/watch?v=y3xSiIOwmLg',
  'https://www.youtube.com/watch?v=YklJdy9uy_w',
  'https://www.youtube.com/watch?v=oHiHVPKi4CI',
  'https://www.youtube.com/watch?v=xkGfSI_ewFg',
  'https://www.youtube.com/watch?v=0iCGm47wwtc',
  'https://www.youtube.com/watch?v=pgMuqvG0EJ0',
  'https://www.youtube.com/watch?v=rxaXUN9vhyM',
  'https://www.youtube.com/watch?v=7JGZtU5nVps',
  'https://www.youtube.com/watch?v=E_QYE2tKStY',
  'https://www.youtube.com/watch?v=JbG8GU8CC7U',
  'https://www.youtube.com/watch?v=wLkwvwxanLo',
  'https://www.youtube.com/watch?v=LUmlYts6Hcw',
  'https://www.youtube.com/watch?v=zukW2h75j3c',
  'https://www.youtube.com/watch?v=8TlE10uwMjY',
  'https://www.youtube.com/watch?v=ogyEvA9Liyo',
  'https://www.youtube.com/watch?v=GsKElhGT67o',
  'https://www.youtube.com/watch?v=ba2V37G3al0',
  'https://www.youtube.com/watch?v=RuUtoUM1eAk',
  'https://www.youtube.com/watch?v=WHB-rScN0k4',
  'https://www.youtube.com/watch?v=DJkvfCe9m-g',
  'https://www.youtube.com/watch?v=j2dqtTTqBUk',
  'https://www.youtube.com/watch?v=eRERiU3fi_8',
  'https://www.youtube.com/watch?v=4OJFq-OO3EE',
  'https://www.youtube.com/watch?v=Ah5oXzWcDew',
  'https://www.youtube.com/watch?v=tmHm887xktY',
  'https://www.youtube.com/watch?v=6cvEgetzsIQ',
  'https://www.youtube.com/watch?v=whW_MtellQU',
];

const DigitalMedia: React.FC = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const SCOPE_OPTIONS = {
    scope: 'pages.DigitalMedia',
  };
  const { t } = useLocale();

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const MediaItem = MEDIAS.map((media, index) => (
    <button
      className="relative shadow rounded overflow-hidden h-96 transition duration-300 hover:shadow-xl"
      onClick={() => openLightboxOnSlide(index + 1)}
      key={media}
    >
      <img
        src={`https://img.youtube.com/vi/${media.split('?v=')[1]}/0.jpg`}
        alt=""
        className="w-full h-full object-cover"
      />
    </button>
  ));

  return (
    <Layout>
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <Breadcrumb
        items={[
          { path: '/', name: t('labels.home', SCOPE_OPTIONS) },
          { path: '/digital-media', name: t('labels.digitalMedia', SCOPE_OPTIONS) },
        ]}
      />

      <section className="py-20">
        <div className="container mx-auto px-1">
          <div className="grid lg:grid-cols-3 gap-6">{MediaItem}</div>
        </div>
      </section>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={MEDIAS}
        slide={lightboxController.slide}
      />
    </Layout>
  );
};

export default DigitalMedia;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
